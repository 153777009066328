"use strict";

import {addClass, removeClass, findAncestorByClass, hasClass} from "./helpers";

const defaults = {
    selectors: {
        submitBtn: '.event-button',
        elements: '.inputgroup .js-input-with-label',
        parentSelector: '.inputgroup',
    },
    classes: {
        errorClass: 'has-input-error',
        activeClass: 'has-active-input',
        filledClass: 'has-filled-input',
        initClass: 'is-label-init',
    }
};

const state = {
    elements: null,
    parentSelector: null,
};

function bindFormLabels () {

    Array.from(state.elements).forEach(element => {

        if (hasClass(element, defaults.classes.initClass)) {
            return false
        }

        if(element.value.trim() !== '') {
            addClass(element.parentNode, defaults.classes.filledClass);
        }

        let inputVal = element.value.trim();

        element.addEventListener('focus', onInputFocus);
        element.addEventListener('change', onInputFocus);
        element.addEventListener('blur', onInputBlur);

        addClass(element, defaults.classes.initClass);

        document.querySelector(defaults.selectors.submitBtn).addEventListener('click', function() {
            element.addEventListener('keyup', onInputFocusAfterSubmit);

            if(element.required) {
                if(element.value.trim() === '') {
                    addClass(element.parentNode, defaults.classes.errorClass);
                    if(element.parentNode.querySelector('.event-label-empty')) {
                        element.parentNode.querySelector('.event-label-empty').style.display = 'block';
                    }
                } else {
                    removeClass(element.parentNode, defaults.classes.errorClass);
                    if(element.parentNode.querySelector('.event-label-empty')) {
                        element.parentNode.querySelector('.event-label-empty').style.display = 'none';
                    }
                    if(element.type === 'email') {
                        if (/(^\w.*@\w+\.\w)/.test(element.value.trim())) {
                            removeClass(element.parentNode, defaults.classes.errorClass);
                            if(element.parentNode.querySelector('.event-label-invalid')) {
                                element.parentNode.querySelector('.event-label-invalid').style.display = 'none';
                            }
                        } else {
                            addClass(element.parentNode, defaults.classes.errorClass);
                            if(element.parentNode.querySelector('.event-label-invalid')) {
                                element.parentNode.querySelector('.event-label-invalid').style.display = 'block';
                            }
                        }
                    }
                }
            }
        })

    });
}

const onInputFocus = function( ev ) {
    let parent = findAncestorByClass(ev.currentTarget, defaults.selectors.parentSelector);
    addClass(parent, defaults.classes.filledClass);
    addClass(parent, defaults.classes.activeClass);
};

const onInputFocusAfterSubmit = function( ev ) {
    var element = ev.currentTarget;

    if(element.required) {
        if(element.value.trim() === '') {
            addClass(element.parentNode, defaults.classes.errorClass);
            if(element.parentNode.querySelector('.event-label-empty')) {
                element.parentNode.querySelector('.event-label-empty').style.display = 'block';
            }
        } else {
            removeClass(element.parentNode, defaults.classes.errorClass);
            if(element.parentNode.querySelector('.event-label-empty')) {
                element.parentNode.querySelector('.event-label-empty').style.display = 'none';
            }
            if(element.type === 'email') {
                if (/(^\w.*@\w+\.\w)/.test(element.value.trim())) {
                    removeClass(element.parentNode, defaults.classes.errorClass);
                    if(element.parentNode.querySelector('.event-label-invalid')) {
                        element.parentNode.querySelector('.event-label-invalid').style.display = 'none';
                    }
                } else {
                    addClass(element.parentNode, defaults.classes.errorClass);
                    if(element.parentNode.querySelector('.event-label-invalid')) {
                        element.parentNode.querySelector('.event-label-invalid').style.display = 'block';
                    }
                }
            }
        }
    }
};

const onInputBlur = function ( ev ) {
    removeClass(ev.currentTarget.parentNode, defaults.classes.activeClass);

    let inputVal = ev.currentTarget.value.trim();

    if(inputVal === '') {
        let parent = findAncestorByClass(ev.currentTarget, defaults.selectors.parentSelector);
        removeClass(parent, defaults.classes.filledClass);
    }
};


export default function initFormLabels() {
    state.elements = document.querySelectorAll(defaults.selectors.elements);
    state.parentSelector = defaults.selectors.parentSelector;

    bindFormLabels()
}
